export enum AccountNodeType {
  Type = 'TYPE',
  Class = 'CLASS',
  Group = 'GROUP',
  Root = 'ROOT',
  Global = 'GLOBAL',
  Local = 'LOCAL',
}

export type AccountNodeNameDictionary = Record<string, string>;

export interface AccountNode {
  /**
   * Unique identifier of the account node.
   */
  id: string;

  /**
   * Unique number identifier of the account node.
   * it contains the hierarchy of the account node.
   * Each level of the hierarchy is separated by 2 digits.
   */
  identifier: number;

  /**
   * The type of the account node.
   * The TYPE node is the highest level of the hierarchy.
   */
  kind: AccountNodeType;

  /**
   * The name of the account node in different languages.
   * @example { en: 'Type', et: 'Tüüp' }
   */
  name: AccountNodeNameDictionary;

  /**
   * Parent identifier of the account node.
   * It is null for TYPE nodes.
   */
  parentId: string | null;

  /**
   * The description of the account node.
   */
  description: string;

  /**
   * The limit of the account node.
   */
  limit?: number;

  /**
   * The date when the account node was created.
   */
  createdAt?: string;

  /**
   * The date when the account node was last updated.
   */
  updatedAt?: string;
}
