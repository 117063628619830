import { AccountNodeType } from '../models';
import { NODE_TYPES } from '../constatnst';

/**
 * Get the node type by identifier
 * @example getNodeTypeByIdentifier(12); // AccountNodeType.Class
 */
export function getNodeTypeByIdentifier(identifier: number): AccountNodeType {
  const parsed = parseIdentifier(identifier);
  const charsLength = parsed.length || 1;
  const levelIndex = charsLength - 1;
  return NODE_TYPES[levelIndex];
}

/**
 * Parse the identifier to a hierarchy object
 * @example parseIdentifierToHierarchyObject(123); // { Type: 1, Class: 2, Group: 3 }
 */
function parseIdentifierToHierarchyObject(identifier: number): Record<string, number> {
  const identifierStr = parseIdentifier(identifier);
  const result: Record<string, number> = {};

  for (let i = 0; i < identifierStr?.length; i++) {
    const key = NODE_TYPES[i];
    if (key) {
      result[key] = parseInt(identifierStr[i], 10); // Map each character to its respective level
    }
  }

  return result;
}

/**
 * Get the level identifier
 * @example getLevelIdentifier(123, AccountNodeType.Class); // 2
 */
export function getLevelIdentifier(identifier: number, type: AccountNodeType): number | null {
  const identifierStr = parseIdentifier(identifier);
  const level = NODE_TYPES.indexOf(type) + 1; // Get the level of the type (1-indexed)
  if (level < 1 || level > identifierStr.length) {
    return null; // Return null if the level is out of range
  }

  const key = NODE_TYPES[level - 1]; // Get the key for the specific level (1-indexed)
  return key ? parseInt(identifierStr[level - 1], 10) : null;
}

/**
 * Parse the identifier to the string.
 */
export function parseIdentifier(identifier: number): string {
  return identifier ? identifier.toString(10) : null;
}
