import { Account } from '@models/account.model';
import { AccountNode, AccountNodeType } from '../models';

const fromResponsePayload = (payload: AccountNode): Account.Type => ({
  uuid: payload.id,
  typeCode: payload.identifier,
  calculationRule: payload.description,
  nameInEnglish: payload.name.en,
  nameInEstonian: payload.name.et,
});

const toTypeName = (node: Account.Type): { english: string; estonian: string } => ({
  english: node?.nameInEnglish || '',
  estonian: node?.nameInEstonian || '',
});

const toQueryInput = (node: Account.Type): AccountNode => ({
  id: node.uuid,
  name: {
    en: node.nameInEnglish,
    et: node.nameInEstonian,
  },
  description: node.calculationRule,
  identifier: node.typeCode,
  kind: AccountNodeType.Type,
  parentId: null,
});

export const typeNodeAdapter = {
  fromResponsePayload,
  toQueryInput,
  toTypeName,
};
