import {
  Directive,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';

import { AccessLevel } from './access-level.enum';
import { PermissionCheckService } from './permission-check.service';

interface AccessPayload {
  context: string;
  level: AccessLevel;
}

@Directive({
  selector: '[ngAccess]',
  standalone: true,
})
export class NgAccessDirective {
  private _context = {
    $implicit: null,
    ngAccess: null,
  };

  @Input() set ngAccess(value: AccessPayload) {
    this._context.$implicit = this._context.ngAccess = value;
    this._updateView();
  }

  @Output() ngAccessChange = new EventEmitter<boolean>();

  constructor(
    private tmpRef: TemplateRef<null>,
    private viewRef: ViewContainerRef,
    private permissionService: PermissionCheckService
  ) {}

  private render() {
    this.viewRef.createEmbeddedView(this.tmpRef);
  }

  private clear() {
    this.viewRef.clear();
  }

  _updateView(): void {
    const { context, level } = this._context.$implicit || {};
    if (!context || !level) {
      this.render();
      return;
    }

    this.permissionService.hasPermissionFor(context, level).subscribe((permitted) => {
      if (permitted) {
        this.render();
      } else {
        this.clear();
      }

      this.ngAccessChange.emit(permitted);
    });
  }
}
