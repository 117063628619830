import { Directive, TemplateRef, ViewContainerRef, inject, effect, input } from '@angular/core';

import { AccessLevel } from '@common/enums';
import { PermissionCheckService } from './permission-check.service';

interface AccessPayload {
  context: string;
  level: AccessLevel;
}

@Directive({
  selector: '[ngAccess]',
  standalone: true,
})
export class NgAccessDirective {
  private tmpRef = inject<TemplateRef<null>>(TemplateRef);
  private viewRef = inject(ViewContainerRef);
  private permissionService = inject(PermissionCheckService);

  readonly ngAccess = input<AccessPayload>(null);

  private _context = {
    $implicit: null,
    ngAccess: null,
  };

  private render() {
    this.viewRef.createEmbeddedView(this.tmpRef);
  }

  private clear() {
    this.viewRef.clear();
  }

  _updateView(): void {
    const { context, level } = this._context.$implicit || {};
    if (!context || !level) {
      this.render();
      return;
    }

    this.permissionService.hasPermissionFor(context, level).subscribe((permitted) => {
      this.clear();
      if (permitted) {
        this.render();
      }
    });
  }

  constructor() {
    effect(() => {
      this._context.$implicit = this._context.ngAccess = this.ngAccess();
      this._updateView();
    });
  }
}
