import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { RouterLink } from '@angular/router';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { SharedModule } from '@shared/shared.module';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatDividerModule } from '@angular/material/divider';
import { ThemePalette } from '@angular/material/core';

import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { Animations } from '@shared/animations';
import { LanguageNamePipe } from '@shared/global-pipes/language.pipe';
import { NgAccessDirective } from '@app/permissions/ng-access.directive';
import { TrBadgeComponent } from '@shared/components/tr-badge/tr-badge.component';
import { TrBreadcrumbComponent, TrBreadcrumbItemDirective } from '@shared/modules/tr-breadcrumbs';
import { selectLanguages } from '@store/selectors/util.selectors';
import { CompanyContractStatusIndicator, ICompany } from '@domains/company';
import {
  UkuIntegrationStatusComponent,
  UserHasUkuAccessDirective,
} from '@domains/integration/features/uku';

import { HeaderLabel, HeaderMenuItem, LayoutAction, LayoutActionType } from '../../types';
import { CompanyContractStatus } from './components/company-contract-status-indicator';
import { EInvoiceContractStatus } from './components/e-invoice-contract-status-indicator';
import { ClosingPeriodStatus } from './components/company-closing-period-indicator';

@Component({
  selector: 'app-main-layout-header',
  standalone: true,
  imports: [
    CommonModule,
    MatSidenavModule,
    MatToolbarModule,
    MatButtonModule,
    SharedModule,
    MatIconModule,
    MatMenuModule,
    RouterLink,
    MatTooltipModule,
    MatDividerModule,
    TrBreadcrumbComponent,
    TrBadgeComponent,
    TrBreadcrumbItemDirective,
    NgOptimizedImage,
    LanguageNamePipe,
    CompanyContractStatusIndicator,
    CompanyContractStatus,
    ClosingPeriodStatus,
    EInvoiceContractStatus,
    NgAccessDirective,
    UkuIntegrationStatusComponent,
    UserHasUkuAccessDirective,
  ],
  templateUrl: './main-layout-header.component.html',
  styleUrls: ['./main-layout-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [Animations.fadeSlideOutIn],
})
export class MainLayoutHeaderComponent implements OnInit {
  @Input() menuItems: HeaderMenuItem[];

  @Input() isViewerActive: boolean;

  @Input() theme: ThemePalette;

  @Input() showCompanyInfo: boolean;

  @Input() sidebarMinimized: boolean;

  @Input() label: HeaderLabel;

  @Input() selectedLanguage: string;

  @Input() selectedCompany: ICompany | null;

  @Output() action = new EventEmitter<LayoutAction>();

  animationDisabled = true;

  languages$ = this.store.pipe(select(selectLanguages));

  constructor(
    private store: Store,
    public translate: TranslateService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.animationDisabled = false;
  }

  openViewer() {
    this.handleAction({
      type: LayoutActionType.ShowFileViewer,
    });
  }

  handleAction(action: LayoutAction) {
    this.action.emit(action);
  }

  navigateTo(url: string) {
    this.action.emit({
      type: LayoutActionType.Navigate,
      payload: {
        url,
      },
    });
  }

  switchCompany() {
    this.action.emit({
      type: LayoutActionType.SwitchCompany,
    });
  }

  updateCompanyInfo(uuid: string): void {
    this.action.emit({
      type: LayoutActionType.UpdateCompanyInfo,
      payload: { uuid },
    });
  }

  changeLang(lang) {
    this.translate.use(lang);
    this.changeDetectorRef.detectChanges();
  }
}
