import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { Configs } from '@shared/configs.model';

import { environment } from '@env/environment';
import { AppConfigs } from '@src/configs';
import { RSQL } from '@shared/global-services/rsql';
import { createContext, withCacheEnabled, withInvalidateCache } from '@shared/http/contexts';

import {
  AccountNode,
  AccountLabel,
  AccountNodeApi,
  AccountNodeType,
  AccountNodeApiQueryInput,
  ClassAccountNodeApiQueryInput,
  GlobalAccountNodeApiQueryInput,
  GroupAccountNodeApiQueryInput,
  LocalAccountNodeApiQueryInput,
  RootAccountNodeApiQueryInput,
  TypedAccountNodeApiQueryInput,
} from '../models';

import PaginationModel = Configs.PaginationModel;
import { map } from 'rxjs/operators';

@Injectable()
export class HttpAccountNodeApiProvider implements AccountNodeApi {
  private readonly _baseUrl = `${environment.trigon.mainUrl}/accounts`;
  private readonly _baseLocalUrl = `${this._baseUrl}/company/:companyID`;

  private readonly _client = inject(HttpClient);

  private _getBaseUrlByKind(kind: AccountNodeType): string {
    return kind === AccountNodeType.Local ? this._baseLocalUrl : this._baseUrl;
  }

  private _createQueryParams(queryInput: AccountNodeApiQueryInput, includeSort: boolean): any {
    let params = new HttpParams();
    const commands = [];
    params = params.set('pageIndex', queryInput.pageIndex || 0);
    params = params.set('pageSize', queryInput.pageSize || AppConfigs.DEFAULT_PAGE_SIZE);

    if (includeSort) {
      params = params.set('sort', 'identifier,asc');
    }

    if (queryInput.kind) {
      commands.push(RSQL.equal('kind', queryInput.kind));
    }

    if (queryInput.parentId) {
      commands.push(RSQL.equal('parentId', queryInput.parentId));
    }

    if (queryInput.id) {
      commands.push(RSQL.equal('id', queryInput.id));
    }

    if (queryInput.typeCode) {
      commands.push(RSQL.equal('typeCode', queryInput.typeCode));
    }

    if (queryInput.classCode) {
      commands.push(RSQL.equal('classCode', queryInput.classCode));
    }

    if (queryInput.groupCode) {
      commands.push(RSQL.equal('groupCode', queryInput.groupCode));
    }

    if (queryInput.rootCode) {
      commands.push(RSQL.equal('rootCode', queryInput.rootCode));
    }

    if (commands.length) {
      params = params.set('rsql', RSQL.compose(...commands));
    }

    return params;
  }

  getTypeNodes(
    queryInput: TypedAccountNodeApiQueryInput
  ): Observable<Configs.PaginationModel<AccountNode>> {
    return this.queryNodes({
      ...queryInput,
      kind: AccountNodeType.Type,
    });
  }

  getClassNodes(
    queryInput: ClassAccountNodeApiQueryInput
  ): Observable<Configs.PaginationModel<AccountNode>> {
    return this.queryNodes({
      ...queryInput,
      kind: AccountNodeType.Class,
    });
  }

  getGroupNodes(
    queryInput: GroupAccountNodeApiQueryInput
  ): Observable<Configs.PaginationModel<AccountNode>> {
    return this.queryNodes({
      ...queryInput,
      kind: AccountNodeType.Group,
    });
  }

  getRootNodes(
    queryInput: RootAccountNodeApiQueryInput
  ): Observable<Configs.PaginationModel<AccountNode>> {
    return this.queryNodes({
      ...queryInput,
      kind: AccountNodeType.Root,
    });
  }

  getGlobalAccounts(
    queryInput: GlobalAccountNodeApiQueryInput
  ): Observable<Configs.PaginationModel<AccountNode>> {
    return this.queryNodes({
      ...queryInput,
      kind: AccountNodeType.Global,
    });
  }

  getLocalAccounts(
    queryInput: LocalAccountNodeApiQueryInput
  ): Observable<Configs.PaginationModel<AccountNode>> {
    return this.queryLocalNodes({
      ...queryInput,
      kind: AccountNodeType.Local,
    });
  }

  queryNodes(
    queryInput: AccountNodeApiQueryInput
  ): Observable<Configs.PaginationModel<AccountNode>> {
    const endpoint = this._baseUrl;
    const params = this._createQueryParams(queryInput, true);
    return this._client.get<Configs.PaginationModel<AccountNode>>(endpoint, {
      params,
      context: createContext(
        withCacheEnabled({ strategy: 'storage', requestContext: 'account-node' })
      ),
    });
  }

  queryLocalNodes(
    queryInput: AccountNodeApiQueryInput
  ): Observable<Configs.PaginationModel<AccountNode>> {
    const endpoint = this._baseLocalUrl;
    const params = this._createQueryParams(queryInput, true);
    return this._client.get<Configs.PaginationModel<AccountNode>>(endpoint, { params });
  }

  createNode(kind: AccountNodeType, node: Partial<AccountNode>): Observable<AccountNode> {
    const endpoint = this._getBaseUrlByKind(kind);
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this._client.post<AccountNode>(endpoint, node, {
      headers,
      context: createContext(withInvalidateCache({ requestContext: 'account-node' })),
    });
  }

  updateNode(kind: AccountNodeType, node: Partial<AccountNode>): Observable<AccountNode> {
    const endpoint = this._getBaseUrlByKind(kind);
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this._client
      .put<AccountNode>(`${endpoint}/${node.id}`, node, {
        headers,
        context: createContext(withInvalidateCache({ requestContext: 'account-node' })),
      })
      .pipe(map(() => node as AccountNode));
  }

  deleteNode(kind: AccountNodeType, identifier: string): Observable<void> {
    const endpoint = this._getBaseUrlByKind(kind);
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this._client.delete<void>(`${endpoint}/${identifier}`, {
      headers,
      context: createContext(withInvalidateCache({ requestContext: 'account-node' })),
    });
  }

  getLocalLabels(queryInput: {
    pageSize: number;
    pageIndex: number;
  }): Observable<PaginationModel<AccountLabel>> {
    const endpoint = `${this._baseLocalUrl}/labels`;
    const params = this._createQueryParams(queryInput, false);
    return this._client.get<PaginationModel<AccountLabel>>(endpoint, { params });
  }

  updateLocalLabel(id: string, changes: Partial<AccountLabel>): Observable<AccountLabel> {
    const endpoint = `${this._baseLocalUrl}/labels/${id}`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this._client.put<AccountLabel>(endpoint, changes, { headers });
  }

  createLocalLabel(label: Pick<AccountLabel, 'label' | 'accountId'>): Observable<AccountLabel> {
    const endpoint = `${this._baseLocalUrl}/labels`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this._client.post<AccountLabel>(endpoint, label, { headers });
  }

  deleteLocalLabel(id: string): Observable<void> {
    const endpoint = `${this._baseLocalUrl}/labels/${id}`;
    return this._client.delete<void>(endpoint);
  }
}
