import { AccountNodeType } from '../models';

export const NODE_TYPES: Array<AccountNodeType> = [
  AccountNodeType.Type,
  AccountNodeType.Class,
  AccountNodeType.Group,
  AccountNodeType.Root,
  AccountNodeType.Global,
  AccountNodeType.Local,
];
