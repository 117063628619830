import { Injectable, inject } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable, shareReplay } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';

import { AppConfigs } from '@src/configs';
import { CACHE_STORAGE, CacheStorage } from '@shared/cache';
import { DISABLE_BROWSER_CACHE, INVALIDATE_CACHE, IS_CACHE_ENABLED } from '@shared/http/contexts';

import CACHE_MAX_AGE = AppConfigs.CACHE_MAX_AGE;

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
  private cacheStorage = inject<CacheStorage>(CACHE_STORAGE);

  private readonly queue: Map<string, Observable<HttpEvent<unknown>>> = new Map();

  intercept<T = unknown>(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<T>> {
    if (this.shouldDisableCache(req)) {
      const modifiedReq = req.clone({
        setHeaders: { 'Cache-Control': 'no-cache' },
      });

      return next.handle(modifiedReq);
    }

    if (this.shouldApplyCache(req)) {
      const { strategy, maxAge, requestContext } = req.context.get(IS_CACHE_ENABLED);

      if (strategy === 'storage') {
        const queuedRequest = this.queue.get(req.urlWithParams);
        const cachedResponse = this.cacheStorage.get<HttpResponse<T>>(req.urlWithParams);

        if (queuedRequest) {
          return queuedRequest as any;
        }

        if (cachedResponse) {
          return new Observable<HttpEvent<T>>((observer) => {
            observer.next(
              new HttpResponse({
                ...cachedResponse,
              })
            );
            observer.complete();
          });
        }

        const shared = next.handle(req).pipe(
          tap((event) => {
            if (event instanceof HttpResponse && event.ok) {
              this.cacheStorage.set(req.urlWithParams, event, maxAge || CACHE_MAX_AGE);
              if (requestContext) {
                this.cacheStorage.setRequestContext(requestContext, req.urlWithParams);
              }
            }
          }),
          finalize(() => this.queue.delete(req.urlWithParams)),
          shareReplay()
        );

        this.queue.set(req.urlWithParams, shared);
        return shared;
      }

      if (strategy === 'network') {
        const modifiedReq = req.clone({
          setHeaders: {
            'Cache-Control': `private, max-age=${maxAge || CACHE_MAX_AGE}`,
          },
        });
        return next.handle(modifiedReq);
      }

      return next.handle(req);
    }

    if (this.shouldInvalidateCache(req)) {
      const { requestContext } = req.context.get(INVALIDATE_CACHE);
      this.cacheStorage.clearByContext(requestContext);
    }

    return next.handle(req);
  }

  private shouldDisableCache(req: HttpRequest<any>): boolean {
    return req.context.has(DISABLE_BROWSER_CACHE);
  }

  private shouldApplyCache(req: HttpRequest<any>): boolean {
    return req.method === 'GET' && req.context.has(IS_CACHE_ENABLED);
  }

  private shouldInvalidateCache(req: HttpRequest<any>): boolean {
    const allowMethods = ['PUT', 'PATCH', 'DELETE', 'POST'];
    return allowMethods.includes(req.method) && req.context.has(INVALIDATE_CACHE);
  }
}
