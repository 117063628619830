import { Account } from '@models/account.model';
import { AccountNode, AccountNodeType } from '../models';
import { getLevelIdentifier } from '../utils';

const createIdentifier = (type: string, classCode: string, groupCode: string): number => {
  return parseInt(`${type}${classCode}${groupCode}`, 10);
};

const fromResponsePayload = (payload: AccountNode): Account.Group => ({
  uuid: payload.id,
  parentId: payload.parentId,
  identifier: payload.identifier,
  nameInEnglish: payload.name.en,
  nameInEstonian: payload.name.et,
  description: payload.description,
  typeCode: getLevelIdentifier(payload.identifier, AccountNodeType.Type),
  classCode: getLevelIdentifier(payload.identifier, AccountNodeType.Class),
  groupCode: getLevelIdentifier(payload.identifier, AccountNodeType.Group),
  connectedTo: false,
});

const toQueryInput = (node: Account.Group): AccountNode => ({
  id: node.uuid,
  name: {
    en: node.nameInEnglish,
    et: node.nameInEstonian,
  },
  parentId: node.parentId,
  identifier: createIdentifier(
    node.typeCode.toString(),
    node.classCode.toString(),
    node.groupCode.toString()
  ),
  kind: AccountNodeType.Group,
  description: node.description,
});

export const groupNodeAdapter = {
  fromResponsePayload,
  toQueryInput,
};
