import { inject, Injectable } from '@angular/core';
import { Observable, of, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';

import { Account } from '@models/account.model';
import { Configs } from '@shared/configs.model';
import { GlobalAccount, IAccount, LocalAccount } from '@domains/account';
import { AppConfigs } from '@src/configs';
import { batchQuery } from '@shared/utils';

import { ACCOUNT_NODE_API_PROVIDER } from './account-node-api.provider';
import {
  typeNodeAdapter,
  classNodeAdapter,
  groupNodeAdapter,
  rootNodeAdapter,
  globalNodeAdapter,
  localNodeAdapter,
} from '../adapters';
import { AccountNodeType } from '../models';
import { AccountAggregatorApiService } from './account-aggregator-api.service';

const BATCH_SIZE = 1024;

@Injectable({
  providedIn: 'root',
})
export class AccountApiV2Service {
  private readonly _provider = inject(ACCOUNT_NODE_API_PROVIDER);
  private readonly _aggregatorProvider = inject(AccountAggregatorApiService);

  getTypes(): Observable<Account.Type[]> {
    return this._provider
      .getTypeNodes({})
      .pipe(map((response) => response.data.map(typeNodeAdapter.fromResponsePayload)));
  }

  getLocalTypes(): Observable<Account.Type[]> {
    return this.getTypes();
  }

  getClasses(): Observable<Account.Class[]> {
    return this._provider
      .getClassNodes({})
      .pipe(map((response) => response.data.map(classNodeAdapter.fromResponsePayload)));
  }

  queryClasses({ typeCode = null }: Configs.AccountQueryParams): Observable<Account.Class[]> {
    return this.getClasses();
  }

  getGroups(queryInput: {
    pageSize: number;
    pageIndex: number;
    id?: string;
  }): Observable<Configs.PaginationModel<Account.Group>> {
    return this._provider.getGroupNodes(queryInput).pipe(
      map((response) => ({
        ...response,
        data: response.data.map((r) => groupNodeAdapter.fromResponsePayload(r)),
      }))
    );
  }

  getGroup(uuid: string): Observable<Account.Group> {
    return this.getGroups({
      pageIndex: 0,
      pageSize: 1,
      id: uuid,
    }).pipe(
      map((response) => {
        const group = response.data[0];
        return group || null;
      })
    );
  }

  queryGroups({
    typeCode = null,
    classCode = null,
  }: Configs.AccountQueryParams): Observable<Account.Group[]> {
    return this.getGroups({
      pageIndex: 0,
      pageSize: BATCH_SIZE,
    }).pipe(map((response) => response.data));
  }

  createGroup(body: Account.Group): Observable<Account.Group> {
    return this._provider
      .createNode(AccountNodeType.Group, groupNodeAdapter.toQueryInput(body))
      .pipe(map((response) => groupNodeAdapter.fromResponsePayload(response)));
  }

  updateGroup(uuid: string, body: Account.Group): Observable<Account.Group> {
    return this._provider
      .updateNode(AccountNodeType.Group, groupNodeAdapter.toQueryInput({ ...body, uuid }))
      .pipe(map((response) => groupNodeAdapter.fromResponsePayload(response)));
  }

  deleteGroup(uuid: string): Observable<void> {
    return this._provider.deleteNode(AccountNodeType.Group, uuid);
  }

  isUniqueGroup(code: string, uuid?: string): Observable<boolean> {
    return this.getGroups({
      pageIndex: 0,
      pageSize: BATCH_SIZE,
    }).pipe(
      map((response) =>
        response.data.every((group) => group.groupCode !== Number(code) || group.uuid === uuid)
      )
    );
  }

  getRoots({
    pageIndex = 0,
    pageSize = AppConfigs.DEFAULT_PAGE_SIZE,
    searchKeyword = null,
  }: {
    pageIndex?: any;
    pageSize?: any;
    searchKeyword?: any;
  }): Observable<Configs.PaginationModel<Account.Root>> {
    return this._provider.getRootNodes({ pageIndex, pageSize }).pipe(
      map((response) => ({
        ...response,
        data: response.data.map((r) => rootNodeAdapter.fromResponsePayload(r)),
      }))
    );
  }

  queryRoots({
    typeCode = null,
    classCode = null,
    groupCode = null,
  }: Configs.AccountQueryParams): Observable<Account.Root[]> {
    return this.getRoots({
      pageIndex: 0,
      pageSize: BATCH_SIZE,
    }).pipe(map((response) => response.data));
  }

  getRoot(uuid: string): Observable<Account.Root> {
    return this._provider.getRootNodes({ id: uuid }).pipe(
      map((response) => {
        const root = response.data[0];
        return rootNodeAdapter.fromResponsePayload(root);
      })
    );
  }

  createRoot(body: Account.Root): Observable<Account.Root> {
    return this._provider
      .createNode(AccountNodeType.Root, rootNodeAdapter.toQueryInput(body))
      .pipe(map((response) => rootNodeAdapter.fromResponsePayload(response)));
  }

  updateRoot(uuid: string, body: Account.Root): Observable<Account.Root> {
    return this._provider
      .updateNode(
        AccountNodeType.Root,
        rootNodeAdapter.toQueryInput({
          ...body,
          uuid,
        })
      )
      .pipe(map((response) => rootNodeAdapter.fromResponsePayload(response)));
  }

  deleteRoot(uuid: string): Observable<void> {
    return this._provider.deleteNode(AccountNodeType.Root, uuid);
  }

  isUniqueRoot(code: string, uuid?: string): Observable<boolean> {
    return this.getRoots({
      pageIndex: 0,
      pageSize: BATCH_SIZE,
    }).pipe(
      map((response) =>
        response.data.every((root) => root.rootCode !== Number(code) || root.uuid === uuid)
      )
    );
  }

  getGlobalAccounts(
    {
      pageIndex = 0,
      pageSize = AppConfigs.DEFAULT_PAGE_SIZE,
      searchKeyword = null,
      companyUUID = null,
    }: Configs.GetQueryParams,
    cache?: boolean
  ): Observable<Configs.PaginationModel<IAccount>> {
    return this.getTypes().pipe(
      switchMap((types) =>
        this._provider.getGlobalAccounts({ pageIndex, pageSize }).pipe(
          map((response) => ({
            ...response,
            data: response.data.map((r) => {
              const accountData = globalNodeAdapter.fromResponsePayload(r);
              const typeNode = types.find((type) => type.typeCode === accountData.typeCode);
              return {
                ...accountData,
                typeName: typeNodeAdapter.toTypeName(typeNode),
              };
            }),
          }))
        )
      )
    );
  }

  /**
   * @deprecated, not used
   */
  getAllAccounts(params: Configs.GetQueryParams): Observable<Configs.PaginationModel<IAccount>> {
    return this.getTypes().pipe(
      switchMap((types) => {
        return batchQuery(BATCH_SIZE, ({ pageIndex, pageSize }) =>
          this._provider.getGlobalAccounts({ pageIndex, pageSize })
        ).pipe(
          map((response) => ({
            ...response,
            data: response.data.map((r) => {
              const accountData = globalNodeAdapter.fromResponsePayload(r);
              const typeNode = types.find((type) => type.typeCode === accountData.typeCode);
              return {
                ...accountData,
                typeName: typeNodeAdapter.toTypeName(typeNode),
              };
            }),
          }))
        );
      })
    );
  }

  getAllGlobalAccounts(): Observable<IAccount[]> {
    return this.getTypes().pipe(
      switchMap((types) => {
        return batchQuery(BATCH_SIZE, ({ pageIndex, pageSize }) =>
          this._provider.getGlobalAccounts({ pageIndex, pageSize })
        ).pipe(
          map((response) =>
            response.data.map((r) => {
              const accountData = globalNodeAdapter.fromResponsePayload(r);
              const typeNode = types.find((type) => type.typeCode === accountData.typeCode);
              return {
                ...accountData,
                typeName: typeNodeAdapter.toTypeName(typeNode),
              };
            })
          )
        );
      })
    );
  }

  getGlobalAccount(uuid: string): Observable<IAccount> {
    return this._provider
      .getGlobalAccounts({ id: uuid })
      .pipe(map((response) => globalNodeAdapter.fromResponsePayload(response.data[0])));
  }

  queryGlobalAccounts({
    typeCode = null,
    classCode = null,
    groupCode = null,
    rootCode = null,
  }: Configs.AccountQueryParams): Observable<IAccount[]> {
    // Helper function to normalize and convert codes to strings
    const normalizeCodes = (codes: any | any[]): string[] => {
      return (Array.isArray(codes) ? codes : [codes])
        .filter((code) => code !== null)
        .map((code) => code.toString());
    };

    // Normalize all input codes
    const typeCodes = normalizeCodes(typeCode);
    const classCodes = normalizeCodes(classCode);
    const groupCodes = normalizeCodes(groupCode);
    const rootCodes = normalizeCodes(rootCode);

    // Helper function to check if an account matches the filters
    const matchesFilters = (account: IAccount): boolean => {
      if (typeCodes.length > 0 && !typeCodes.includes(account.typeCode.toString())) {
        return false;
      }
      if (classCodes.length > 0 && !classCodes.includes(account.classCode.toString())) {
        return false;
      }
      if (groupCodes.length > 0 && !groupCodes.includes(account.groupCode.toString())) {
        return false;
      }
      if (rootCodes.length > 0 && !rootCodes.includes(account.rootCode.toString())) {
        return false;
      }
      return true;
    };

    return this.getAllGlobalAccounts().pipe(map((accounts) => accounts.filter(matchesFilters)));
  }

  createGlobalAccount(body: IAccount): Observable<IAccount> {
    return this._provider
      .createNode(AccountNodeType.Global, globalNodeAdapter.toQueryInput(body as GlobalAccount))
      .pipe(
        switchMap((response) =>
          this.getTypes().pipe(
            map((types) => {
              const node = globalNodeAdapter.fromResponsePayload(response);
              const typeNode = types.find((type) => type.typeCode === node.typeCode);
              return {
                ...node,
                typeName: typeNodeAdapter.toTypeName(typeNode),
              };
            })
          )
        )
      );
  }

  deleteGlobalAccount(uuid: string): Observable<void> {
    return this._provider.deleteNode(AccountNodeType.Global, uuid);
  }

  isUniqueGlobalAccount(code: string, uuid?: string): Observable<boolean> {
    return this.getGlobalAccounts({
      pageIndex: 0,
      pageSize: BATCH_SIZE,
    }).pipe(
      map((response) =>
        response.data.every(
          (globalAccount) => globalAccount.globalCode !== code || globalAccount.uuid === uuid
        )
      )
    );
  }

  updateGlobalAccount(uuid: string, body: IAccount): Observable<IAccount> {
    return this._provider
      .updateNode(
        AccountNodeType.Global,
        globalNodeAdapter.toQueryInput({
          ...body,
          uuid,
        } as GlobalAccount)
      )
      .pipe(map((response) => globalNodeAdapter.fromResponsePayload(response)));
  }

  getLocalAccounts({
    pageIndex = 0,
    pageSize = AppConfigs.DEFAULT_PAGE_SIZE,
    searchKeyword = null,
    parentId = null,
  }: Configs.GetQueryParams): Observable<Configs.PaginationModel<IAccount>> {
    return this.getAllGlobalAccounts().pipe(
      switchMap((globalAccounts) =>
        this._provider.getLocalAccounts({ pageIndex, pageSize, parentId }).pipe(
          map((response) => ({
            ...response,
            data: response.data.map((r) => {
              const accountData = localNodeAdapter.fromResponsePayload(r);
              const globalAccount = globalAccounts.find(
                (ga) => ga.uuid === accountData.globalAccountUUID
              ) as GlobalAccount;
              return {
                ...accountData,
                typeName: globalAccount.typeName,
                prefix: globalNodeAdapter.toPrefix(globalAccount),
              };
            }),
          }))
        )
      )
    );
  }

  queryLocalAccounts({
    typeCode = null,
    classCode = null,
    groupCode = null,
    rootCode = null,
    globalCode = null,
  }: Configs.AccountQueryParams): Observable<IAccount[]> {
    return this.getLocalAccounts({
      pageIndex: 0,
      pageSize: BATCH_SIZE,
    }).pipe(map((response) => response.data));
  }

  getLocalAccount(uuid: string): Observable<IAccount> {
    return this._provider
      .getLocalAccounts({
        id: uuid,
      })
      .pipe(
        switchMap((response) => {
          return response?.data?.length
            ? this.getAllGlobalAccounts().pipe(
                map((globalAccounts) => {
                  const accountData = localNodeAdapter.fromResponsePayload(response.data[0]);
                  const globalAccount = globalAccounts.find(
                    (ga) => ga.uuid === accountData.globalAccountUUID
                  ) as GlobalAccount;
                  return {
                    ...accountData,
                    typeName: globalAccount.typeName,
                    prefix: globalNodeAdapter.toPrefix(globalAccount),
                  };
                })
              )
            : of(null);
        })
      );
  }

  createLocalAccount(body: IAccount): Observable<IAccount> {
    const input = localNodeAdapter.toQueryInput(body as LocalAccount);
    return this._provider
      .createNode(AccountNodeType.Local, input)
      .pipe(switchMap((response) => this.getLocalAccount(response.id)));
  }

  updateLocalAccount(uuid: string, body: IAccount): Observable<IAccount> {
    const payload = localNodeAdapter.toQueryInput({ ...body, uuid } as LocalAccount);
    return this._provider
      .updateNode(AccountNodeType.Local, payload)
      .pipe(map(() => ({ ...body, uuid })));
  }

  getLabels(): Observable<Account.Label[]> {
    return this._provider.getLocalLabels({ pageIndex: 0, pageSize: BATCH_SIZE }).pipe(
      map((response) =>
        response.data.map((r) => ({
          uuid: r.id,
          name: r.label,
          accountId: r.accountId,
        }))
      )
    );
  }

  attachLabelToGlobalAccount(uuid: string, label: string): Observable<Account.Label> {
    return this._provider.createLocalLabel({ label, accountId: uuid }).pipe(
      map((response) => ({
        uuid,
        name: label,
        accountId: null,
      }))
    );
  }

  reattachLabelToGlobalAccount(
    uuid: string,
    accountId: string,
    label: string
  ): Observable<Account.Label> {
    return this._provider.updateLocalLabel(uuid, { label, accountId }).pipe(
      map((response) => ({
        uuid,
        accountId,
        name: label,
      }))
    );
  }

  deattachLabelFromGlobalAccount(uuid: string, companyUuid: string): Observable<void> {
    return this._provider.deleteLocalLabel(uuid);
  }

  deleteLocalAccount(uuid: string): Observable<void> {
    return this._provider.deleteNode(AccountNodeType.Local, uuid);
  }

  /**
   * @deprecated, not used
   */
  downloadXlsxBalanceSheet(): Observable<{ excelUrl: string }> {
    return undefined;
  }

  /**
   * @deprecated, not used
   */
  downloadXlsxProfitAndLoss(): Observable<{ excelUrl: string }> {
    return undefined;
  }

  generateGeneralLedger({
    from,
    to,
    accountFromUUID,
    accountToUUID,
    vatRateUUID,
    labels,
    expenseAndRevenueTurnover,
    daily,
  }: any): Observable<Object> {
    return this._aggregatorProvider.generateGeneralLedger({
      from,
      to,
      accountFromUUID,
      accountToUUID,
      vatRateUUID,
      labels,
      expenseAndRevenueTurnover,
      daily,
    });
  }

  generateProfitAndLoss({ from, to }: { from: any; to: any }): Observable<Object> {
    return this._aggregatorProvider.generateProfitAndLoss({ from, to });
  }

  /**
   * @deprecated, not used
   */
  getById(uuid: string): Observable<IAccount> {
    return undefined;
  }
}
