import { Account } from '@models/account.model';
import { AccountNode, AccountNodeType } from '../models';
import { getLevelIdentifier } from '../utils';

const fromResponsePayload = (payload: AccountNode): Account.Class => ({
  uuid: payload.id,
  parentId: payload.parentId,
  identifier: payload.identifier,
  nameInEnglish: payload.name.en,
  nameInEstonian: payload.name.et,
  description: payload.description,
  typeCode: getLevelIdentifier(payload.identifier, AccountNodeType.Type),
  classCode: getLevelIdentifier(payload.identifier, AccountNodeType.Class),
});

const toQueryInput = (node: Account.Class): AccountNode => ({
  id: node.uuid,
  name: {
    en: node.nameInEnglish,
    et: node.nameInEstonian,
  },
  parentId: node.parentId,
  identifier: node.identifier,
  kind: AccountNodeType.Class,
  description: node.description,
});

export const classNodeAdapter = {
  fromResponsePayload,
  toQueryInput,
};
