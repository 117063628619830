import { Component, OnInit, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import * as UtilsActions from './store/actions/util.actions';
import { skip } from 'rxjs/operators';
import { NotificationService } from './_shared/notification/notification.service';
import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { Configs } from './_shared/configs.model';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { MaintenanceComponent } from './_shared/maintenance.component';
import { MaintenanceService } from './_shared/maintenance.service';

export const DEFAULT_LANG = 'et-EE';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: false
})
export class AppComponent implements OnInit {
  private matIconRegistry = inject(MatIconRegistry);
  private domSanitizer = inject(DomSanitizer);
  private maintenanceService = inject(MaintenanceService);
  private overlay = inject(Overlay);

  title = 'trigon';
  favIcon: HTMLLinkElement = document.querySelector('#favIcon');
  overlayRef: OverlayRef;
  constructor() {
    const matIconRegistry = this.matIconRegistry;
    const domSanitizer = this.domSanitizer;
    const translate = inject(TranslateService);
    const store = inject(Store);
    const ntf = inject(NotificationService);

    Configs.SVG_ICONS.forEach(([label, url]) =>
      matIconRegistry.addSvgIcon(label, domSanitizer.bypassSecurityTrustResourceUrl(url))
    );
    if (!environment.production) {
      this.favIcon.href = './stage-favicon.png';
    }
    store.dispatch(UtilsActions.loadFormConfigs());
    translate.addLangs(['en-US', 'et-EE']);
    translate.setDefaultLang(DEFAULT_LANG);
    const selectedLang = localStorage.getItem('lang');
    if (selectedLang == null) {
      localStorage.setItem('lang', DEFAULT_LANG);
    }

    translate.use(selectedLang || DEFAULT_LANG);
    translate.onLangChange.pipe(skip(1)).subscribe(({ lang }) => {
      localStorage.setItem('lang', lang);
    });
  }

  openMaintenanceOverlay() {
    this.overlayRef = this.overlay.create();
    const componentPortal = new ComponentPortal(MaintenanceComponent);
    this.overlayRef.addPanelClass('maintenance-overlay');
    this.overlayRef.attach(componentPortal);
  }

  ngOnInit(): void {
    this.maintenanceService.maintenanceData$.subscribe((maintenanceData) => {
      if (maintenanceData.status) {
        this.openMaintenanceOverlay();
      }
    });
  }
}
