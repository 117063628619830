import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, inject, provideAppInitializer } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import {
  AngularFirePerformanceModule,
  PerformanceMonitoringService,
} from '@angular/fire/compat/performance';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent, DEFAULT_LANG } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';
import { CoreModule } from './core/core.module';
import { registerLocaleData } from '@angular/common';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatIconModule } from '@angular/material/icon';
import {
  TranslateModule,
  MissingTranslationHandler,
  MissingTranslationHandlerParams,
  TranslateLoader,
} from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import localeEt from '@angular/common/locales/et';
import {
  HttpClient,
  provideHttpClient,
  withInterceptors,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { MatDialogModule, MAT_DIALOG_SCROLL_STRATEGY } from '@angular/material/dialog';
import { httpInterceptorProviders } from './_http-interceptors';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { NotificationModule } from './_shared/notification/notification.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { AngularFireAnalyticsModule } from '@angular/fire/compat/analytics';
import {
  FullscreenOverlayContainer,
  NoopScrollStrategy,
  OverlayContainer,
  OverlayModule,
} from '@angular/cdk/overlay';
import { MaintenanceComponent } from '@shared/maintenance.component';
import { SelfViewerModule } from './self-viewer/self-viewer.module';
import { QuillConfigModule } from 'ngx-quill';
import { Observable } from 'rxjs';
import { AppInitService } from './app-init.service';
import { AppConfigs } from '@src/configs';
import DEFAULT_LOCALE = AppConfigs.DEFAULT_LOCALE;
import { unsavedLabelsInterceptor } from '@app/_http-interceptors/unsaved-labels.interceptor';
import { PermissionsModule } from '@app/permissions/permissions.module';
import { AccountApiV2Service, accountNodeApiProvider } from '@domains/account-v2';
import { AccountsApiService } from '@domains/account/data-access';
import { provideRootStore } from '@app/store';
import {
  ModuleRegistry,
  provideGlobalGridOptions,
  CellStyleModule,
  CheckboxEditorModule,
  ClientSideRowModelApiModule,
  ClientSideRowModelModule,
  ColumnApiModule,
  ColumnAutoSizeModule,
  EventApiModule,
  PinnedRowModule,
  RenderApiModule,
  RowApiModule,
  RowSelectionModule,
  RowStyleModule,
  TooltipModule,
  ValidationModule,
} from 'ag-grid-community';

// the second parameter is optional
registerLocaleData(localeEt, DEFAULT_LOCALE);

// ag-grid global options and modules.
provideGlobalGridOptions({ theme: 'legacy', rowHeight: 48 });
ModuleRegistry.registerModules([
  RowApiModule,
  TooltipModule,
  RowStyleModule,
  EventApiModule,
  ColumnApiModule,
  CellStyleModule,
  PinnedRowModule,
  RenderApiModule,
  ValidationModule,
  RowSelectionModule,
  ColumnAutoSizeModule,
  CheckboxEditorModule,
  ClientSideRowModelModule,
  ClientSideRowModelApiModule,
]);

export class TrigonMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    return `*${params.key}*`;
  }
}

export function getCurrentLocale() {
  return localStorage.getItem('lang') || DEFAULT_LANG;
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function initializeApp(appInitService: AppInitService) {
  return (): Observable<any> => {
    return appInitService.Init();
  };
}

@NgModule({
  declarations: [AppComponent, MaintenanceComponent],
  imports: [
    OverlayModule,
    MatDatepickerModule,
    MatMomentDateModule,
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    MatSnackBarModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatIconModule,
    AppRoutingModule,
    SelfViewerModule,
    MatButtonModule,
    PermissionsModule.forContext(''),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirePerformanceModule,
    AngularFireAnalyticsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: TrigonMissingTranslationHandler,
      },
    }),
    NotificationModule,
    QuillConfigModule.forRoot({
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'], // toggled buttons
          [{ header: [1, 2, 3, 4, 5, 6, false] }],

          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ direction: 'rtl' }], // text direction

          [{ color: [] }, { background: [] }], // dropdown with defaults from theme
          [{ align: [] }],

          ['link', 'image'], // link and image, video
          ['code'],
        ],
      },
    }),
  ],
  providers: [
    provideRootStore(),
    { provide: MAT_DATE_LOCALE, useValue: 'et-EE' },
    { provide: MAT_DIALOG_SCROLL_STRATEGY, useValue: () => new NoopScrollStrategy() },
    ...httpInterceptorProviders,
    {
      provide: OverlayContainer,
      useClass: FullscreenOverlayContainer,
    },
    PerformanceMonitoringService,
    {
      provide: LOCALE_ID,
      useFactory: getCurrentLocale,
      deps: [],
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration: 3000,
        horizontalPosition: 'right',
        verticalPosition: 'top',
      },
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' },
    },
    AppInitService,
    provideHttpClient(withInterceptorsFromDi(), withInterceptors([unsavedLabelsInterceptor])),
    provideAppInitializer(() => {
      const initializerFn = initializeApp(inject(AppInitService));
      return initializerFn();
    }),
    accountNodeApiProvider(),
    { provide: AccountsApiService, useClass: AccountApiV2Service },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
