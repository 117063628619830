import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CoreComponent } from './core/core.component';
import { redirectLoggedInTo, redirectUnauthorizedTo } from '@angular/fire/auth-guard';
import { AngularFireAuthGuard } from '@angular/fire/compat/auth-guard';
import { PrivilegesGuard } from './_guards/privileges.guard';
import { SuperPrivilegesGuard } from './_guards/super-priveleges.guard';
import { UUIDMatchGuard } from '@app/_guards/uuid-match.guard';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['sign-in']);
const redirectLoggedInToItems = () => redirectLoggedInTo(['/']);

const routes: Routes = [
  {
    path: 'playground',
    loadChildren: () => import('./_playground').then((m) => m.ROUTES),
  },
  {
    path: 'doc-viewer',
    loadChildren: () => import('./self-viewer/self-viewer.module').then((m) => m.SelfViewerModule),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./passwordless-auth/passwordless-auth.module').then((m) => m.PasswordlessAuthModule),
  },
  {
    path: '404',
    loadChildren: () => import('./not-found/not-found.module').then((m) => m.NotFoundModule),
  },
  {
    path: 'sign-in',
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectLoggedInToItems },
    loadChildren: () => import('./sign-in/sign-in.module').then((m) => m.SignInModule),
  },
  {
    path: 'unauthorized',
    loadChildren: () =>
      import('./unauthorized/unauthorized.module').then((m) => m.UnauthorizedModule),
  },
  {
    path: 'chk',
    loadChildren: () => import('@domains/chk').then((c) => c.ROUTES),
  },
  {
    path: '',
    component: CoreComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
    children: [
      {
        path: '',
        canActivate: [SuperPrivilegesGuard],
        loadChildren: () => import('@app/_containers/entry-container').then((c) => c.ROUTES),
      },
      {
        path: 'settings',
        canActivate: [SuperPrivilegesGuard],
        loadChildren: () => import('@app/_containers/sudo-container').then((c) => c.ROUTES),
      },
      {
        path: ':companyUuid',
        canActivate: [UUIDMatchGuard, PrivilegesGuard],
        loadChildren: () => import('@app/_containers/core-container').then((c) => c.ROUTES),
      },
    ],
  },
  { path: '**', redirectTo: '404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
