import { GlobalAccount } from '@domains/account';
import { Account } from '@models/account.model';

import { AccountNode, AccountNodeType } from '../models';
import { getLevelIdentifier } from '../utils';

const createIdentifier = (
  type: string,
  classCode: string,
  groupCode: string,
  rootCode: string,
  globalCode: string
): number => {
  return parseInt(`${type}${classCode}${groupCode}${rootCode}${globalCode}`, 10);
};

const fromResponsePayload = (payload: AccountNode): GlobalAccount => ({
  uuid: payload.id,
  rootUUID: payload.parentId,
  identifier: payload.identifier,
  nameInEnglish: payload.name.en,
  nameInEstonian: payload.name.et,
  typeCode: getLevelIdentifier(payload.identifier, AccountNodeType.Type),
  classCode: getLevelIdentifier(payload.identifier, AccountNodeType.Class),
  groupCode: getLevelIdentifier(payload.identifier, AccountNodeType.Group),
  rootCode: getLevelIdentifier(payload.identifier, AccountNodeType.Root),
  // The code is a combination of last two levels.
  code: `${getLevelIdentifier(payload.identifier, AccountNodeType.Global)}${getLevelIdentifier(payload.identifier, AccountNodeType.Local)}`,
  description: payload.description,
  limit: payload.limit,
  connectedTo: false,
});

const toQueryInput = (node: GlobalAccount): Partial<AccountNode> => ({
  id: node.uuid,
  name: {
    en: node.nameInEnglish,
    et: node.nameInEstonian,
  },
  description: node.description || null,
  parentId: node.rootUUID,
  identifier: createIdentifier(
    node.typeCode.toString(),
    node.classCode.toString(),
    node.groupCode.toString(),
    node.rootCode.toString(),
    node.code.toString()
  ),
  kind: AccountNodeType.Global,
  limit: node.limit,
});

const toPrefix = (account: GlobalAccount): { english: string; estonian: string } => {
  return { english: account.nameInEnglish || '', estonian: account.nameInEstonian || '' };
};

export const globalNodeAdapter = {
  fromResponsePayload,
  toQueryInput,
  toPrefix,
};
