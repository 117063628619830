import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from '@env/environment';

@Injectable({ providedIn: 'root' })
export class AccountAggregatorApiService {
  private readonly _baseUrl: string = `${environment.trigon.mainUrl}/aggregator/accounts/company/:companyID`;

  private readonly _client = inject(HttpClient);

  private _createQueryParams(queryInput: Record<string, any>): HttpParams {
    let params = new HttpParams();

    if (queryInput.from) {
      params = params.set('from', queryInput.from);
    }

    if (queryInput.to) {
      params = params.set('to', queryInput.to);
    }

    if (queryInput.accountFromUUID) {
      params = params.set('accountFromUUID', queryInput.accountFromUUID);
    }

    if (queryInput.accountToUUID) {
      params = params.set('accountToUUID', queryInput.accountToUUID);
    }

    if (queryInput.vatRateUUID) {
      params = params.set('vatRateUUID', queryInput.vatRateUUID);
    }

    if (queryInput.labels) {
      params = params.set('labels', queryInput.labels.toString());
    }

    if (queryInput.expenseAndRevenueTurnover) {
      params = params.set('expenseAndRevenueTurnover', queryInput.expenseAndRevenueTurnover);
    }

    if (queryInput.daily) {
      params = params.set('daily', queryInput.daily);
    }

    if (queryInput.labels) {
      params = params.set('labels', queryInput.labels.toString());
    }

    return params;
  }

  generateGeneralLedger(queryInput: any) {
    const params = this._createQueryParams(queryInput);
    return this._client.get(`${this._baseUrl}/new_general-ledger`, { params });
  }

  generateProfitAndLoss(queryInput: any) {
    const params = this._createQueryParams(queryInput);
    const endpoint = `${this._baseUrl}/profitandloss/detailed`;
    return this._client.get(endpoint, { params });
  }
}
