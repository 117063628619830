import { GlobalAccount, LocalAccount } from '@domains/account';

import { AccountNode, AccountNodeType } from '../models';
import { getLevelIdentifier } from '../utils';

const getSequenceNumber = (node: AccountNode): string => {
  const globalCode = getLevelIdentifier(node.identifier, AccountNodeType.Global);
  const localCode = getLevelIdentifier(node.identifier, AccountNodeType.Local);
  return `${globalCode}${localCode}`;
};

const getPrefix = (account: GlobalAccount): { english: string; estonian: string } => {
  return { english: account.nameInEnglish || '', estonian: account.nameInEstonian || '' };
};

const fromResponsePayload = (payload: AccountNode): LocalAccount => ({
  uuid: payload.id,
  identifier: payload.identifier,
  nameInEnglish: payload.name.en,
  nameInEstonian: payload.name.et,
  globalAccountUUID: payload.parentId,
  typeCode: getLevelIdentifier(payload.identifier, AccountNodeType.Type),
  classCode: getLevelIdentifier(payload.identifier, AccountNodeType.Class),
  groupCode: getLevelIdentifier(payload.identifier, AccountNodeType.Group),
  rootCode: getLevelIdentifier(payload.identifier, AccountNodeType.Root),
  globalCode: getLevelIdentifier(payload.identifier, AccountNodeType.Global).toString(),
  sequenceNumber: getSequenceNumber(payload),
  prefix: getPrefix({} as any),
  limit: payload.limit,
  connectedTo: false,
});

const toQueryInput = (node: LocalAccount): Partial<AccountNode> => ({
  id: node.uuid,
  name: {
    en: node.nameInEnglish,
    et: node.nameInEstonian,
  },
  identifier: node.identifier,
  kind: AccountNodeType.Local,
  parentId: node.globalAccountUUID,
});

export const localNodeAdapter = {
  fromResponsePayload,
  toQueryInput,
};
