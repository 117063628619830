import { inject, Injectable, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class DomService {
  private readonly document = inject(DOCUMENT);
  private readonly rendererFactory = inject(RendererFactory2);
  private readonly renderer = this.rendererFactory.createRenderer(null, null);

  public getDocument(): Document {
    return this.document;
  }

  public getElement(selector: string): HTMLElement {
    return this.document.querySelector(selector);
  }

  public removeElement(element: HTMLElement): void {
    if (!element) {
      return;
    }
    element.remove();
  }

  public hideElementWithTransition<T extends HTMLElement = HTMLElement>(
    element: T,
    duration: number = 300,
    callback?: (el: T) => void
  ): void {
    if (!element) {
      return;
    }
    // Set initial opacity to make sure the transition happens
    this.renderer.setStyle(element, 'opacity', '1');
    this.renderer.setStyle(element, 'transition', `opacity ${duration}ms ease`);

    // Apply opacity to zero to initiate the transition
    setTimeout(() => {
      this.renderer.setStyle(element, 'opacity', '0');

      // Optional: remove the element from DOM after transition ends
      setTimeout(() => {
        this.renderer.setStyle(element, 'display', 'none');
        if (callback && typeof callback === 'function') {
          callback(element);
        }
      }, duration);
    });
  }
}
