import {
  EnvironmentProviders,
  inject,
  InjectionToken,
  makeEnvironmentProviders,
} from '@angular/core';

import { LOCAL_STORAGE } from '@shared/tokens';
import { StorageKeys } from '@shared/constants';

import { AccountNodeApi } from '../models';
import { MockAccountNodeApiProvider } from './mock-account-node-api.provider';
import { HttpAccountNodeApiProvider } from './http-account-node-api.provider';

export const ACCOUNT_NODE_API_PROVIDER = new InjectionToken<AccountNodeApi>(
  'ACCOUNT_NODE_API_PROVIDER'
);

export const accountNodeApiProvider = (): EnvironmentProviders =>
  makeEnvironmentProviders([
    {
      provide: ACCOUNT_NODE_API_PROVIDER,
      useFactory: (storage = inject(LOCAL_STORAGE)) =>
        !!storage.getItem(StorageKeys.enableAccountMockData)
          ? new MockAccountNodeApiProvider()
          : new HttpAccountNodeApiProvider(),
    },
  ]);
