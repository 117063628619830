import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppConfigs } from '@src/configs';
import { Configs } from '@shared/configs.model';
import { getEmptyPaginatedModel } from '@shared/utils';
import { CURRENT_COMPANY_STORE } from '@domains/company';
import { createContext, withCacheEnabled } from '@shared/http/contexts';

import {
  AccountNode,
  AccountLabel,
  AccountNodeApi,
  AccountNodeApiQueryInput,
  AccountNodeType,
  ClassAccountNodeApiQueryInput,
  GlobalAccountNodeApiQueryInput,
  GroupAccountNodeApiQueryInput,
  LocalAccountNodeApiQueryInput,
  RootAccountNodeApiQueryInput,
  TypedAccountNodeApiQueryInput,
} from '../models';

@Injectable()
export class MockAccountNodeApiProvider implements AccountNodeApi {
  private readonly baseUrl = '/assets/json/mock/accounts-fun.json';
  private readonly baseUrlLocal = '/assets/json/mock/accounts-local-fun.json';

  private readonly _client = inject(HttpClient);
  private readonly _store = inject(CURRENT_COMPANY_STORE);

  private applyFilters(
    queryInput: AccountNodeApiQueryInput,
    data: AccountNode[]
  ): Configs.PaginationModel<AccountNode> {
    const pageIndex = queryInput.pageIndex || 0;
    const pageSize = queryInput.pageSize || AppConfigs.DEFAULT_PAGE_SIZE;
    const totalPageCount = Math.ceil(data.length / pageSize);
    const sliced = data.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize);

    return {
      pageIndex,
      data: sliced,
      totalElementsCount: data.length,
      totalPages: totalPageCount,
    };
  }

  getTypeNodes(
    queryInput: TypedAccountNodeApiQueryInput
  ): Observable<Configs.PaginationModel<AccountNode>> {
    return this.queryNodes({
      ...queryInput,
      kind: AccountNodeType.Type,
    });
  }

  getClassNodes(
    queryInput: ClassAccountNodeApiQueryInput
  ): Observable<Configs.PaginationModel<AccountNode>> {
    return this.queryNodes({
      ...queryInput,
      kind: AccountNodeType.Class,
    });
  }

  getGroupNodes(
    queryInput: GroupAccountNodeApiQueryInput
  ): Observable<Configs.PaginationModel<AccountNode>> {
    return this.queryNodes({
      ...queryInput,
      kind: AccountNodeType.Group,
    });
  }

  getRootNodes(
    queryInput: RootAccountNodeApiQueryInput
  ): Observable<Configs.PaginationModel<AccountNode>> {
    return this.queryNodes({
      ...queryInput,
      kind: AccountNodeType.Root,
    });
  }

  getGlobalAccounts(
    queryInput: GlobalAccountNodeApiQueryInput
  ): Observable<Configs.PaginationModel<AccountNode>> {
    return this.queryNodes({
      ...queryInput,
      kind: AccountNodeType.Global,
    });
  }

  getLocalAccounts(
    queryInput: LocalAccountNodeApiQueryInput
  ): Observable<Configs.PaginationModel<AccountNode>> {
    return this.queryLocalNodes({
      ...queryInput,
      kind: AccountNodeType.Local,
    });
  }

  queryNodes(
    queryInput: AccountNodeApiQueryInput
  ): Observable<Configs.PaginationModel<AccountNode>> {
    const endpoint = this.baseUrl;
    return this._client
      .get<{ data: Array<AccountNode> }>(endpoint, {
        context: createContext(withCacheEnabled({ strategy: 'storage' })),
      })
      .pipe(
        map((res) => res.data.filter((node) => node.kind === queryInput.kind)),
        map((response) => this.applyFilters(queryInput, response))
      );
  }

  queryLocalNodes(
    queryInput: AccountNodeApiQueryInput
  ): Observable<Configs.PaginationModel<AccountNode>> {
    const endpoint = this.baseUrlLocal;
    const companyId = this._store.id();
    return this._client
      .get<Record<string, { data: Array<AccountNode> }>>(endpoint, {
        context: createContext(withCacheEnabled({ strategy: 'storage' })),
      })
      .pipe(
        map((res) => (res && res[companyId] ? res[companyId].data : [])),
        map((response) => this.applyFilters(queryInput, response))
      );
  }

  createNode(kind: AccountNodeType, node: Partial<AccountNode>): Observable<AccountNode> {
    return of(node as AccountNode);
  }

  updateNode(kind: AccountNodeType, node: Partial<AccountNode>): Observable<AccountNode> {
    return of(node as AccountNode);
  }

  deleteNode(kind: AccountNodeType, identifier: string): Observable<void> {
    return of(undefined);
  }

  getLocalLabels(queryInput: {
    pageSize: number;
    pageIndex: number;
  }): Observable<Configs.PaginationModel<AccountLabel>> {
    return of(getEmptyPaginatedModel<AccountLabel>());
  }

  createLocalLabel(label: Pick<AccountLabel, 'label' | 'accountId'>): Observable<AccountLabel> {
    return of(label as AccountLabel);
  }

  updateLocalLabel(id: string, changes: Partial<AccountLabel>): Observable<AccountLabel> {
    return of({
      ...changes,
      id,
    } as AccountLabel);
  }

  deleteLocalLabel(id: string): Observable<void> {
    return of(undefined);
  }
}
